.content {
  opacity: 0.9;
  display: "flex";
  justify-content: "center";
  flex-direction: "column";
}

.rbc-calendar {
  height: 700px;
}

.form-check .form-check-label {
  padding-left: 23px !important;
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Dodaje trochę przezroczystości dla efektu overlay */
}

.ReactModal__Content {
  position: relative !important;
  width: 60%;
  max-width: 560px;
  margin: 0px 10px 10px 10px !important;
  padding: 20px;
  left: "50%";
  right: "auto";
  top: 0px !important;
  bottom: "auto";
  transform: "translate(-50%, -50%)";
  background: "white";
  opacity: 2;
  height: 97%;
  /* top: "50%";
  margin-right: "-50%"; */
}

@media only screen and (max-width: 640px) {
  .ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: start;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@media only screen and (max-width: 1200px) {
  .ReactModal__Content {
    width: 60%;
    position: relative !important;
    margin: 0px 10px 10px 10px !important;
    left: 13% !important;
    right: auto !important;
    bottom: auto !important;
    background: "white" !important;
    opacity: 2;
    font-size: 13px;
    height: 95%;
  }
}

@media only screen and (max-width: 640px) {
  .ReactModal__Content {
    width: 100%;
    position: relative !important;
    margin: 0px 0px 0px 0px !important;
    left: 0% !important;
    right: auto !important;
    bottom: auto !important;
    background: "white" !important;
    opacity: 2;
  }
}

.rbc-day-slot .rbc-event {
  height: 21.16667% !important;
  padding: 6px;
}

.rbc-day-slot .rbc-event-label {
  display: none;
}

/* @media only screen and (max-width: 640px) {
    .ReactModal__Overlay {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      overflow-y: scroll;
    }
  }

  @media only screen and (max-width: 640px) {
    .ReactModal__Content {
      width: 90%;
      height: 100%;
      max-width: 460px !important;
      position: relative !important;
      top: -229px !important;
      margin: 0px 1px 20px 1px !important;
      left: 1% !important;
      right: auto !important;
      bottom: auto !important;
      background: "white" !important;
      opacity: 100;
      z-index: 1000;
    }
  }
} */
