body {
  margin-top: 20px;
  color: #1a202c;
  text-align: left;
  background-color: #e2e8f0;
}
.inner-wrapper {
  position: relative;
  height: calc(100vh - 3.5rem);
  transition: transform 0.3s;
}
@media (min-width: 992px) {
  .sticky-navbar .inner-wrapper {
    height: calc(100vh - 3.5rem - 48px);
  }
}

.inner-main,
.inner-sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.inner-sidebar {
  left: 0;
  width: 235px;
  border-right: 1px solid #cbd5e0;
  background-color: #fff;
  z-index: 1;
}
.inner-main {
  right: 0;
  left: 235px;
}
.inner-main-footer,
.inner-main-header,
.inner-sidebar-footer,
.inner-sidebar-header {
  height: 3.5rem;
  border-bottom: 1px solid #cbd5e0;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  flex-shrink: 0;
}
.inner-main-body,
.inner-sidebar-body {
  padding: 1rem;
  position: relative;
  flex: 1 1 auto;
}
.inner-main-body .sticky-top,
.inner-sidebar-body .sticky-top {
  z-index: 999;
}
.inner-main-footer,
.inner-main-header {
  background-color: #fff;
}
.inner-main-footer,
.inner-sidebar-footer {
  border-top: 1px solid #cbd5e0;
  border-bottom: 0;
  height: auto;
  min-height: 3.5rem;
}
/* @media (max-width: 767.98px) {
  .inner-sidebar {
    left: -235px;
  }
  .inner-main {
    left: 0;
  }
  .inner-expand .main-body {
    overflow: hidden;
  }
  .inner-expand .inner-wrapper {
    transform: translate3d(235px, 0, 0);
  }
} */

.text-secondary {
  overflow-wrap: anywhere;
}

.p-sm {
  overflow-wrap: anywhere;
}

@media (max-width: 767.98px) {
  .inner-sidebar {
    max-width: 160px;
  }
  .inner-main {
    left: 154px;
    width: 100%;
  }

  .text-secondary {
    overflow-wrap: anywhere;
  }

  .small-text {
    font-size: 10px;
  }

  .pl-sm {
    padding-left: 6px;
  }

  .media {
    display: grid !important;
    text-align: center;
  }

  h6 {
    text-align-last: center;
  }

  .avatar-sm {
    text-align-last: center;
    margin-left: 16px;
  }

  .p-answer p {
    width: 100%;
    max-width: 100%;
  }

  .p-answer img {
    width: 100%;
  }
}

.nav .show > .nav-link.nav-link-faded,
.nav-link.nav-link-faded.active,
.nav-link.nav-link-faded:active,
.nav-pills .nav-link.nav-link-faded.active,
.navbar-nav .show > .nav-link.nav-link-faded {
  color: #3367b5;
  background-color: #c9d8f0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #467bcb;
}
.nav-link.has-icon {
  display: flex;
  align-items: center;
}
.nav-link.active {
  color: #467bcb;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-link {
  color: #4a5568;
}
.card {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.forum-item-header {
  display: flex;
  justify-content: space-between;
}

.ql-editor {
  height: 200px;
}

.ql-tooltip {
  left: 0 !important;
}

.ql-editing {
  left: 0 !important;
}

.p-answer > p {
  display: flex;
  flex-direction: column;
}
