.config-form {
  max-width: 32rem;
  padding: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}

.config-title {
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  font-weight: 600;
}

.strava-connector-container {
  display: flex;
  flex-direction: row;
  gap: 2.5em;
  align-items: center;
}

.strava-button {
  --tw-text-opacity: 1 !important;
  color: #f97316 !important;
  --tw-border-opacity: 1 !important;
  border-color: #f97316 !important;
}

.strava-button:hover {
  --tw-text-opacity: 1 !important;
  color: #ea580c !important;
  --tw-border-opacity: 1 !important;
  border-color: #ea580c !important;
}

.strava-status {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
