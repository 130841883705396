.rbc-month-view.opacity {
  opacity: 0.9;
}

.buttons {
  display: grid !important;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
}

.buttons > Button {
  margin: 3px;
}

.delete-button {
  margin-right: 40px !important;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.mgt-12 {
  margin-top: 12px;
}

.hidden {
  visibility: hidden;
}

::placeholder {
  color: #e3e3e3;
}

.form-control {
  min-width: 115px;
}

.custom-dropdown {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  height: 40px;
  box-shadow: none;
  width: 100%;
}

.input-field {
  max-width: 73% !important;
}

.ql-editor {
  height: 150px !important;
}

.mb-3-custom {
  margin-bottom: 1rem !important;
}

label {
  margin-bottom: 0.5rem !important;
  font-size: 15px;
}

@media only screen and (max-height: 750px) {
  .ql-editor {
    height: 90px !important;
  }

  .ql-toolbar.ql-snow {
    padding: 4px !important;
    max-height: 55px !important;
  }

  textarea.form-control {
    max-height: 70px;
  }
}

@media only screen and (max-width: 640px) {
  .ql-editor {
    height: 90px !important;
  }
  .ql-toolbar.ql-snow {
    padding: 4px !important;
    max-height: 55px !important;
  }

  .ql-snow.ql-toolbar button svg {
    height: 80% !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before {
    font-size: 12px !important;
  }

  textarea.form-control {
    max-height: 50px;
  }

  .custom-dropdown {
    max-height: 35px;
    padding: 2px 12px 2px 12px !important;
  }

  .form-control {
    max-height: 35px;
    padding: 2px 12px 2px 12px !important;
    font-size: 13px !important;
  }

  .btn {
    font-size: 13px !important;
    padding: 4px !important;
  }
}

@media only screen and (max-width: 640px) {
  .input-field {
    max-width: 100% !important;
  }

  .mb-3-custom {
    margin-bottom: 0.5rem !important;
  }

  label {
    margin-bottom: 0.2rem !important;
    font-size: 12px;
  }
}
