.datePicker input {
  width: 92px;
  background-color: #f8f9fa;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.react-datepicker__input-container {
  max-width: 86px;
}

.react-datepicker-wrapper {
  max-width: 90px;
}

.week-day {
  font-weight: bold;
  margin-left: 2px;
}

.nc-icon .nc-button-power svg {
  width: 48px;
  height: 48px;
}
