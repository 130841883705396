.header-block {
    padding: 0 0 12px 0;
}

.exercises-block {
    display: flex;
    column-gap: 12px;
    row-gap:12px;
    flex-wrap: wrap;
}

.footer-block {
    display: flex !important;
    column-gap: 12px;
    justify-content: space-between;
}

.align-flex-to-right {
    align-self: self-end;
}

.set-block {
    font-size: 16px;
    font-weight: 600 !important;
    padding: 12px 0 0 12px;
    font-style: italic;
}