.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.bc1 {
  background-color: rgb(130, 202, 175);
}

.bc2 {
  background-color: rgb(117, 192, 224);
}

.threshold {
  background-color: rgb(255, 150, 70);
}

.vo2max {
  background-color: #ff8181;
}

.pace-description {
  font-size: 13px;
  position: relative;
  bottom: 5px;
}

@media only screen and (max-width: 640px) {
  .dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 1px;
  }

  .pace-description {
    font-size: 10px;
    position: relative;
    bottom: 3px;
  }
}
